import {Col, Form, Modal, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {isBusiness, isConsumer, isDealer, isMexico, useInputDataState, useIsPR, useLabels} from '../../functions'
import {initBusinessFromConsumer, writeToRedis} from '../../state/customerAsyncActions'
import {setLangScript} from '../../state/settingsSlice'
import {FormCheck, FormLabel} from './Form'
import {closeAlert} from '../../state/customerSlice'

export const Scripts = () => {
    const [script, setScript] = useInputDataState('script')
    const labels = useLabels()
    const program = useSelector(state => state.customer.data.program)
    const dispatch = useDispatch()
    const serviceAddress = useSelector(state => state.customer.data.serviceAddress)
    const hideScriptButtons = useSelector(state => state.settings.hideScriptButtons)

    if (hideScriptButtons){
        return <></>
    }

    const onChange = async (e) => {
        setScript(e)
        dispatch(setLangScript(e.target.value))
        await dispatch(writeToRedis())
        if (!isMexico(program) && isBusiness(e.target.value)) {
            await dispatch(initBusinessFromConsumer())
        }
    }

        return <>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <FormLabel bold={true}>{labels.scriptType}</FormLabel>
                        <FormCheck type="radio" onChange={onChange} value="Consumer Residential"
                                   checked={isConsumer(script)} label={labels.scriptConsumer}/>
                        {serviceAddress.state !== 'PR'?
                        <FormCheck disabled={serviceAddress.state === 'PR'} type="radio" onChange={onChange} value="Business"
                                   checked={isBusiness(script)} label={labels.scriptBusiness}/>:null
                    }
                    </Form.Group>
                </Col>
            </Row>
        </>

}

export const HowDidYouHear = () => {
    const mediaType = useSelector(state => state.customer.data.mediaType)
    const isPR1 = useIsPR()
    const options = useSelector(state => {
        if (isDealer(mediaType)) {
            return state.settings.howDidYouHearDealer
        }
        if (isPR1) {
            return state.settings.howDidYouHearPR
        }
        if (isMexico(state.customer.data.program)) {
            return state.settings.howDidYouHearMX
        }

        return state.settings.howDidYouHear
    })
    const [howDidYouHear, setHowDidYouHear] = useInputDataState('howDidYouHear')
    const howDidYouHearValidation = useSelector(state => state.validation.howDidYouHear)
    const labels = useLabels()

    return (<Row className="mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true} required={true}>{labels.howDidYouHear}</FormLabel>
                <Form.Select value={howDidYouHear} onChange={setHowDidYouHear}
                             isInvalid={howDidYouHearValidation !== null}>
                    <option></option>
                    {options.map(option => <option key={option} value={option}>{option}</option>)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{howDidYouHearValidation}</Form.Control.Feedback>
            </Form.Group>
        </Col>
    </Row>)
}


export const FormSetUp = () => {
    const [tfn, setTFN] = useInputDataState('tfn')
    const [ccsource, setCCSource] = useInputDataState('ccsource')
    const [partnerId, setPartnerId] = useInputDataState('partnerid')

    if (process.env.REACT_APP_ENV === 'production') {
        return <></>
    }

    return <>
        <Row className="mb-3">
            <Col md={4}>
                <FormLabel bold={true}>TFN</FormLabel>
                <Form.Control value={tfn} onChange={setTFN}/>
            </Col>
            <Col md={4}>
                <FormLabel bold={true}>CC source</FormLabel>
                <Form.Control value={ccsource} onChange={setCCSource}/>
            </Col>
            <Col md={4}>
                <FormLabel bold={true}>Partner Id</FormLabel>
                <Form.Control value={partnerId} onChange={setPartnerId}/>
            </Col>
        </Row>
    </>
}

export const AlertPopup = () => {
    const message = useSelector(state => state.customer.alertMessage)
    const dispatch = useDispatch()

    const close = () => {
        dispatch(closeAlert())
    }

    return <Modal show={message.isOpen} onHide={close}>
        <Modal.Header closeButton style={{color: 'orange'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
        </Modal.Header>
        <Modal.Body>{message.body}</Modal.Body>
    </Modal>
}

export const Email = (props) => {
    const labels = useLabels()
    const [tcEmail, setTCEmail] = useInputDataState('tcEmail')

    return <>
        <Col {...props}>
            <Form.Group>
                <FormLabel bold={true}>{labels.email}</FormLabel>
                <Form.Control type="email" value={tcEmail} onChange={setTCEmail}/>
            </Form.Group>
        </Col>
    </>
}
