import {useDispatch, useSelector} from 'react-redux'
import {ArrowClockwise, ArrowLeftCircle, ArrowRightCircle} from '../Icons'
import {getSectionNotes, getStringTranslations, setIsAgentNoteOpen} from '../../state/settingsSlice'
import {Col, Row} from 'react-bootstrap'
import {isConsumer} from '../../functions'
import {Sections} from './Sections'
import './header.scss'


export function Header() {
    const firstName = useSelector(state => state.customer.data.serviceAddress.firstName) ?? ''
    const lastName = useSelector(state => state.customer.data.serviceAddress.lastName) ?? ''
    const phoneNumber = useSelector(state => state.customer.data.serviceAddress.phoneNumber) ?? ''
    const offerId = useSelector(state => {
        if (isConsumer(state.customer.data.script)) {
            return state.customer.data.offerId ?? ''
        }
        return state.customer.data.smeOfferId ?? ''
    })
    const caseId = useSelector(state => state.customer.data.caseId) ?? ''
    const agentFirstname = useSelector(state => state.customer.data.agentFirstname)
    const agentId = useSelector(state => state.customer.data.agentId)
    const mediaType = useSelector(state => state.customer.data.mediaType) ?? ''
    const isAgentNoteOpen = useSelector(state => state.settings.isAgentNoteOpen)
    const dispatch = useDispatch()

    const openCloseAgentPanel = () => {
        dispatch(setIsAgentNoteOpen(!isAgentNoteOpen))
    }

    const refreshLabels = () => {
        dispatch(getStringTranslations())
        dispatch(getSectionNotes())
    }

    return (
        <header className="header">
            <Row>
                <Col>
                    <span>{firstName + ' ' + lastName}</span><br/>
                    <span
                        className="info-box">{agentFirstname ? agentFirstname + ' - ' + agentId + ' | ' : ''} {mediaType}{phoneNumber ? ' | ' + phoneNumber : null}{offerId ? ' | ' + offerId : null}</span>
                </Col>
                <Col>
                    <div className="case-info">
                        <span>Case Id: {caseId}</span>
                        <span onClick={openCloseAgentPanel}>{isAgentNoteOpen ? <ArrowRightCircle/> :
                            <ArrowLeftCircle/>}</span>
                        <span onClick={refreshLabels}>
                    <ArrowClockwise/>
                </span>
                    </div>
                </Col>
            </Row>
            {caseId ? <Sections/> : null}
        </header>
    )
}

