import {Alert, Col, Form, Row} from 'react-bootstrap'
import {isMexico, useDataState, useInputDataState, useLabels} from '../../functions'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {FeaturesBusiness} from './FeaturesBusiness'
import {useSelector} from 'react-redux'

export const ProbingQuestionsBusiness = () => {
    const labels = useLabels()
    const [continuity, setContinuity] = useInputDataState('continuity')
    const [buildingType, setBuildingType] = useInputDataState('buildingType')
    const [, setOfferId] = useDataState('offerId')
    const [connection, setConnection] = useInputDataState('connection')
    const program = useSelector(state => state.customer.data.program)
    const connections = useSelector(state => state.settings.connectionsMX)
    const connectionsBusiness = useSelector(state => state.settings.connections)
    const [connectionBusiness, setConnectionBusiness] = useInputDataState('connection')
    const whyNewServiceReasons = useSelector(state => {
        return state.settings.whyNewServiceReasons
    })
    const [whyNewService, setWhyNewService] = useInputDataState('whyNewService')
    const isEPQCodeNewService = useSelector(state => state.customer.data.isEPQCodeNewService)
    const handleContinuity = (e) => {
        setContinuity(e)
        setBuildingType({target: {value: e.target.value === 'Continuity' ? 'Commercial Building' : buildingType}})
        //Offer dedicated to continuity. Not changed in 3 years!
        setOfferId(101)
    }


    return <>

        <Row>
            <Col>
                <Alert>
                    <h3>{labels.probingQuestions}</h3>
                    <h5>{labels.probingQuestionHelp}</h5>
                </Alert>
            </Col>
        </Row>
        <Row className="mb-3">
            {isEPQCodeNewService ?
                <Col>
                    <FormLabel required={true}>{labels.whyCustOrderingNewService}</FormLabel>
                    <Form.Select onChange={setWhyNewService} value={whyNewService}>
                        <option></option>
                        {whyNewServiceReasons.map(o => <option value={o} key={o}>{o}</option>)}
                    </Form.Select>
                </Col>
                : null
            }
        </Row>
        <Row className="mb-3">
            {!isMexico(program) ?

                <Col md={6}>
                    <FormLabel bold={true}>{labels.howInternetToday}</FormLabel>
                    <Form.Select onChange={setConnectionBusiness} value={connectionBusiness}>
                        <option></option>
                        {connectionsBusiness.map(o => <option value={o} key={o}>{o}</option>)}
                    </Form.Select>
                </Col> : null

            }
        </Row>
        <Row className="mb-3">
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true} required={true}>{labels.businessPrimaryService}</FormLabel>
                    <FormCheck type="radio" label="Primary" value="Primary" onChange={handleContinuity}
                               checked={continuity === 'Primary'}/>
                    <FormCheck type="radio" label="Continuity" value="Continuity" onChange={handleContinuity}
                               checked={continuity === 'Continuity'}/>
                </Form.Group>
            </Col>
            {isMexico(program) ? <Col md={6}>
                <FormLabel bold={true}>{labels.howDoYouConnectToInternetBusiness}</FormLabel>
                <Form.Select onChange={setConnection} value={connection}>
                    <option></option>
                    {connections.map(o => <option value={o} key={o}>{o}</option>)}
                </Form.Select>
            </Col> : null}
        </Row>


        <FeaturesBusiness/>
    </>
}
