import {useDispatch, useSelector} from 'react-redux'
import {setACP, setDataState} from './state/customerSlice'
import {useState} from 'react'
import html2canvas from 'html2canvas'
import {
    programMexico,
    scriptAragon,
    scriptArchenia,
    scriptBrightspeed,
    scriptBusiness,
    scriptCenturyLink,
    scriptClearlink,
    scriptConsumer,
    scriptConsumerResidential,
    mediaTypeDealer,
    scriptFrontier,
    scriptFrontierWholesale,
    scriptHRSA,
    scriptHRSAGeneric,
    scriptPowersetter,
    scriptRVEBB,
    scriptWindstream,
    scriptZiply,
    regionPR,
    mediaTypeBroadcast2,
    programUSA, programNonPCI
} from './constants'
import {setAppLoadingState} from './state/settingsSlice'

export const isEPQCodeSuccessful = (state) => {
    return state.settings.successfulPimCodes.includes(state.customer.data.epqPim)
}


export const useInputState = (initialValue) => {
    const [value, setValue] = useState(initialValue)

    return [value, (e) => {
        setValue(e.target.value)
    }]
}

export const useDataState = (key) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state.customer.data[key])
    const setValue = (value) => {
        dispatch(setDataState({key, value}))
    }

    return [value, setValue]
}

export const useInputDataState = (key) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state.customer.data[key])
    return [value, (e) => {
        if (e) {
            dispatch(setDataState({key, value: e.target.value}))
        }
    }]
}

export const useInputDataStateYesNo = (key) => {
    const dispatch = useDispatch()
    const value = useSelector(state => state.customer.data[key])
    return [value, (e) => {
        dispatch(setDataState({key, value: e.target.value === 'Yes'}))
    }]
}

/**
 *
 * @param Date date
 */
export const getDateString = (date) => {
    const year = date.getFullYear()
    let month = (date.getMonth() + 1).toString()
    let day = date.getDate().toString()

    day = day.padStart(2, '0')
    month = month.padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const getACPSetFunction = (dispatch) => {
    return (e) => {
        const name = e.target.name
        const payload = {[name]: e.target.value}
        dispatch(setACP(payload))
    }
}

export const getCredentialsHeader = ({settings}) => {
    let token
    if (settings.ssoToken) {
        token = `Bearer ${settings.ssoToken}`
    } else if (settings.simpleToken) {
        token = `Token ${settings.simpleToken}`
    } else {
        const b64 = btoa(`${settings.username}:${settings.password}`)
        token = `Basic ${b64}`
    }

    return {Authorization: token}
}

export const isInputValid = (data, key) => {
    if (data === undefined || data === null || data[key] === null) {
        return true
    }

    if (typeof data !== 'object') {
        return data !== null
    }

    return data[key] === null
}


export const StringTrans = (props) => {
    const parts = props.str.split('?')
    const ret = []
    for (let i = 0; i < parts.length; i++) {
        ret.push(parts[i])
        if (props.elements.length > i) {
            ret.push(props.elements[i])
        }
    }

    return ret
}

export const getNote = (notes, key) => {
    return notes?.find(item => item.name === key)
}

export const useDataForReplacement = () => {
    return useSelector(state => ({
        plan: state.customer.data.plan,
        firstName: state.customer.data.serviceAddress.firstName,
        lastName: state.customer.data.serviceAddress.lastName,
        phone: state.customer.data.phoneNumber,
    }))
}

export const isBroadcast = (mediaType) => {
    return !!(['broadcast', 'broadcast2', 'outbound'].find(item => mediaType?.toLowerCase() === item))
}


export const isSameScriptType = (scriptA, scriptB) => {
    //Check for consumer types
    if (isConsumer(scriptA) && isConsumer(scriptB)) {
        return true
    }
    //Check for business types
    return scriptA?.toLowerCase() === scriptB?.toLowerCase()
}


export const isConsumer = (script) => {
    const scripts = [scriptConsumer, scriptConsumerResidential, null, undefined, scriptCenturyLink, scriptFrontier, scriptWindstream, scriptClearlink, scriptArchenia, scriptFrontierWholesale, scriptZiply, scriptPowersetter, scriptAragon, scriptRVEBB, scriptHRSAGeneric, scriptBrightspeed, scriptHRSA,]
    const lowerCaseScripts = scripts.map(s => {
        if (s) {
            return s.toLowerCase()
        }
        return s
    })
    return lowerCaseScripts.includes(script?.toLowerCase());
}

export const isMexico = (program) => {
    return program?.toLowerCase() === programMexico
}

export const isExternal = (program) => {
    return program?.toLowerCase() === programNonPCI
}

export const isPR = (state) => {
    return state?.toLowerCase() === regionPR
}

export const isBusiness = (script) => {
    return script?.toLowerCase() === scriptBusiness
}

export const isDealer = (mediaType) => {
    return mediaType?.toLowerCase() === mediaTypeDealer
}

export const isBroadcast2 = (mediaType) => {
    return mediaType?.toLowerCase() === mediaTypeBroadcast2
}

export const isDobValid = (dob) => {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/;

    return !(dob && !regex.test(dob));

}


export const replaceVars = (data, str) => {
    let ret = str
    let start = 0
    while ((start = ret.indexOf('%{', start)) !== -1) {
        const end = ret.indexOf('}', start)
        if (end !== -1) {
            const varName = ret.substring(start + 2, end)
            try {
                // eslint-disable-next-line no-eval
                const val = eval(`data.${varName}`)
                if (val !== null && val !== undefined) {
                    ret = ret.replace(`%{${varName}}`, val)
                }
            } catch (e) {
                console.error(e)
            }
            start = end
        } else {
            start++;
        }
    }
    return ret
}

export const copyKeyValues = (dest, source, keys, keyAlis = {}) => {
    for (const key of keys) {
        let destKey = key
        if (keyAlis.hasOwnProperty(key)) {
            destKey = keyAlis[key]
        }
        dest[destKey] = source[key]
    }
}

export const isProblemWithTheAddressPimCode = (pim) => {
    return ['577', '575', '578', '587', '591', '590', '592'].includes(pim)
}


export const isCenturyLinkAcquisition = (dnis) => {

    return ['8444061688', '8449622641', '8668952852'].includes(dnis)
}


export const useLabels = () => {
    return useSelector(state => state.settings.labels)
}

export const toBlob = (canvas) => {
    return new Promise(resolve => {
        canvas.toBlob(blob => resolve(blob), 'image/png')
    })
}

export const takeScreenShot = async (caseId) => {
    try {
        const canvas = await html2canvas(document.body)
        const blob = await toBlob(canvas)
        const form = new FormData();
        form.append('screenshot', blob);

        const ret = await fetch(`${process.env.REACT_APP_HUSHESAPP_BASE_URL}/api/v2/o/jacada/screenshots/${caseId}?screenshot_access_key=${process.env.REACT_APP_SCREENSHOT_ACCESS_KEY}`, {
            method: 'post', body: form, mode: 'no-cors',
        });

        return ret.ok
    } catch (e) {

    }
    return false
}

export const getBRTMessage = (code, labels) => {
    if (code === '700') {
        return labels.brtMessage700
    }
    if (code === '710') {
        return labels.brtMessage710
    }

    return labels.brtMessage7101
}

export const runAndShowLoader = async (callback, dispatch) => {
    dispatch(setAppLoadingState('pending'))
    await callback()
    dispatch(setAppLoadingState(''))
}


export const useInput = () => {
    const [value, setValue] = useState('')

    return [value, (e) => setValue(e.target.value)]
}

export const useIsMexico = () => useSelector(state => {
    return isMexico(state.customer.data.program)
})

export const useIsPR = () => useSelector(state => isPR(state.customer.data.serviceAddress?.state))

export const useIsUSA = () => useSelector(state => state.customer.data.program === programUSA)

export const useIsExternal = () => useSelector(state => {
    return isExternal(state.customer.data.program)
})

export const useNybbReturned = () => useSelector(state => state.customer.data.nybb_returned)







