import {createSlice} from '@reduxjs/toolkit'
import {setExtraReducers} from './customerAsyncActions'

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        applicationError: false,
        plans: [],
        optionalVoice: [],
        optionalMesh: [],
        optionalRepair: [],
        optionalNorton: [],
        optionalStaticIP: [],
        requestedNewCase: false,
        cart: null,
        isBenefitQualifyingPerson: '',
        cartState: null,
        nybOffer: '',
        nybOfferStatus: '',
        sendPreviewState: '',
        mexicoOffer: null,
        leadSentToSaleForce: false,
        checkDishSubscriberState: '',
        data: {
            script: 'Consumer Residential',
            isCallerSubscribedToDish: 'No',
            isServiceAndBillingAddressTheSame: true,
            isBillingAndPreviousBillingAddressTheSame: true,
            lease: '',
            howDidYouHear: '',
            caseId: '',
            mediaType: '',
            connection: '',
            users: '',
            paymentOption: '',
            serviceAddress: {
                firstName: '',
                lastName: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                country: '',
                zip: '',
                phoneNumber: '',
                overrideAddress: 'false',
                altPhone: '',
                companyName: null
            },
            billingAddress: {
                firstName: '',
                lastName: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                country: '',
                zip: '',
                overrideAddress: 'false',
            },
            previousBillingAddress: {
                firstName: '',
                lastName: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                country: '',
                zip: '',
                overrideAddress: 'false',
            },
            priorityPackage: '',
            smePriorityPackage: '',
            satPackageChoice: '',
            fusionPackageChoice: '',
            agreeToCreditCheck: false,
            epqPass: '',
            plan: '',
            meshSelection: '',
            voipSelection: '',
            expressSelection: '',
            nortonSelection: '',
            staticIPSelection: '',
            installDate: '',
            epqPim: '',
            resId: '',
            creditCard: {
                paymentType: '',
                creditNumber: '',
                expireMonth: '',
                expireYear: '',
                cvv: '',
            },
            acpLearnMore: '',
            ACP: {
                applicationId: '',
                NLADEligibilityProgram: '',
                NLADSchoolName: '',
                tribalId: '',
                NLADTribalId: '',
                NLADPrimaryUrbanization: '',
                NLADBQPFirstName: '',
                NLADBQPLastName: '',
                NLADBQPTribalId: '',
                NLADCustomerConsent: '',
                NLADBQPSSNLast4: '',
                NLADBQPDOB: '',
                NLADRepAssisted: '',
                NLADRepresentativeID: '',
                NLADTransferConsent: '',
            },
            showPaymentOptions: true,
            showCreditCheck: true,
            sendPlansAndPricing: null,
            tcEmail: '',
            taskSid: '',
            callSid: '',
            agentId: '',
            tcPhone: null,
            continuity: '',
            dob: '',
            ssn: '',
            acpAndFusion: false,
            fusionPackages: '',
            customerWantsFusion: '',
            customerRefusedFusion: '',
            devices: '',
            buildingType: '',
            streaming: '',
            staticIp: '',
            program: 'usa',
            documentType: '',
            documentNumber: '',
            documentIssueDate: '',
            documentExpireDate: '',
            orderId: '',
            orderPim: '',
            orderMessage: '',
            cashOrCreditCard: '',
            ccsource: '',
            tfn: '',
            partnerid: '',
            language: 'english',
            isDTV: false,
            landLinePhone: '',
            brtMessageCode: '',
            flexOffer: '',
            flexDecision: '',
            installerNotes: '',
            promoCode: '',
            consumerException: '',
            explainedPricing: '',
            privacyNoticeRead: '',
            taxId: '',
            processedAs: 'Individual',
            tvServiceType: '',
            planToKeepTV: '',
            wishToHearDish: '',
            workFromHome: '',
            vpnUsed: '',
            securityUsed: '',
            isHomeBusiness: '',
            someonePlayVideoGames: '',
            template: '',
            useHomeBusinessChoice: '',
            trans_seq: '',
            contactConsentBroadband: '',
            validEmail: false,
            dishBundleMentioned: '',
            dishSubscriber: {
                accountNumber: '',
                lastName: '',
            },
            dishSubscriberHasNumber: '',
            dish_subscriber_verified: false,
            dshpim: '',
            dshmessage: '',
            dish_subscriber_check_attempted: false,
            dishBundleAccepted: '',
            dish_backend_not_available: false,
            userWantsNycaf: '',
            serviceAvailable: true,
            credit_card_fail_count: 0,
            credit_card_lockout: false,
            transferToDish: '',
            broadbandLabelLink: '',
            isEPQCodeNewService: false,
            showWhyCustOrderingNewService: false,
            whyNewService: '',
            epqAttempt: '',
            epqAttemptBlockedId: '',
            thirdPartyCc: '',


        },
        twillioNumber: null,
        twillioNumberState: '',
        twillioCCInfoState: '',
        showCaseSelector: false,
        cases: [],
        someonePlayVideoGames: '',
        chargesExplained: 'No',
        checkBusinessAddress: false,
        offers: null,
        showOrderButton: false,
        alertMessage: {
            isOpen: false,
            body: ''
        },
        sendBroadbandFactsState: ''
    },
    reducers: {
        setServiceAddress: (state, action) => {
            state.data.serviceAddress = {...state.data.serviceAddress, ...action.payload}
        },
        setBillingAddress: (state, action) => {
            state.data.billingAddress = {...state.data.billingAddress, ...action.payload}
        },
        setPreviousBillingAddress: (state, action) => {
            state.data.previousBillingAddress = {...state.data.previousBillingAddress, ...action.payload}
        },
        setIsServiceAndBillingAddressTheSame: (state, action) => {
            state.data.isServiceAndBillingAddressTheSame = action.payload
        },
        setIsBillingAndPreviousBillingAddressTheSame: (state, action) => {
            state.data.isBillingAndPreviousBillingAddressTheSame = action.payload
        },
        setAllowCreditCheck: (state, action) => {
            state.data.agreeToCreditCheck = action.payload
            state.data.epqPass = '2'
        },
        setDataState: (state, action) => {
            state.data[action.payload.key] = action.payload.value
        },
        setInstallDate: (state, action) => {
            state.data.installDate = action.payload
        },
        setCreditCard: (state, action) => {
            state.data.creditCard = {...state.data.creditCard, ...action.payload}
        },
        setACP: (state, action) => {
            state.data.ACP = {...state.data.ACP, ...action.payload}
        },
        setIsBenefitQualifyingPerson: (state, action) => {
            state.isBenefitQualifyingPerson = action.payload
        },
        setShowCreditCheck: (state, action) => {
            state.data.showCreditCheck = action.payload
        },
        setNYB: (state, action) => {
            state.nybOffer = action.payload
        },
        setUserWantsNycaf: (state, action) => {
            state.userWantsNycaf = action.payload
        },
        setAdditionalArgs: (state, action) => {
            if (action.payload.callSid) {
                state.data.callSid = action.payload.callSid
            }

            if (action.payload.script) {
                state.data.script = action.payload.script
            }
        },
        setProgram: (state, action) => {
            state.data.program = action.payload
        },
        setCustomerWantsFusion: (state, action) => {
            state.data.customerWantsFusion = action.payload
            if (state.data.customerWantsFusion === 'Yes') {
                state.data.showCreditCheck = state.data.partnerid !== '92947'
                state.data.acpFusionCreditCheck = 'True'
            } else {
                state.data.showCreditCheck = false
                state.data.acpFusionCreditCheck = 'False'
            }
        },
        closeAlert: state => {
            state.alertMessage.isOpen = false
            state.alertMessage.body = ''
        },
        openAlert: (state, action) => {
            state.alertMessage.isOpen = true
            state.alertMessage.body = action.payload
        },
        updateCustomerPhoneNumber(state, action) {
            state.data.serviceAddress.phoneNumber = action.payload;
        },
        updateCustomerLandLineNumber(state, action) {
            state.data.landLinePhone = action.payload;
        },
        updateTcPhone(state, action) {
            state.data.tcPhone = action.payload;
        },
        setAllowBroadbandLink: (state, action) => {
            state.data.contactConsentBroadband = action.payload;
        },
        setDishSubscriber: (state, action) => {
            state.data.dishSubscriber = {...state.data.dishSubscriber, ...action.payload}
        },
        setReviewedLabel: (state, action) => {
            state.data.reviewedLabel = action.payload;
        },
        setReadLabel: (state, action) => {
            state.data.readLabel = action.payload;
        },
        setCustomerDeclinedLabel: (state, action) => {
            state.data.customerDeclinedLabel = action.payload;
        },
        setPriorityPackage: (state, action) => {
            state.data.priorityPackage = action.payload
            state.data.fusionPriorityPackage = action.payload
        },
        setDishBundleAccepted: (state, action) => {
            state.setDishBundleAccepted = action.payload
        },
        setServiceAvailable: (state, action) => {
            state.setServiceAvailable = action.payload
        },
        setCredit_card_fail_count: (state, action) => {
            state.setCredit_card_fail_count = action.payload
        },
        setCredit_card_lockout: (state, action) => {
            state.setCredit_card_fail_count = action.payload
        },
        setFirstName: (state, action) => {
            state.data.firstName = {...state.data.firstName, ...action.payload}
        },
        setBroadbandLabelLink: (state, action) => {
            state.data.broadbandLabelLink = {...state.data.broadbandLabelLink, ...action.payload}
        },
        setIsEPQCodeNewService: (state, action) => {
            state.data.isEPQCodeNewService = {...state.data.isEPQCodeNewService, ...action.payload}
        },
        setEpqAttempt: (state, action) => {
            state.data.epqAttempt = {...state.data.epqAttempt, ...action.payload}
        },
        setEpqAttemptBlockedId: (state, action) => {
            state.data.epqAttemptBlockedId = {...state.data.epqAttemptBlockedId, ...action.payload}
        },

    },
    extraReducers: setExtraReducers,
})

export const {
    setFirstName,
    setBillingAddress,
    setPreviousBillingAddress,
    setServiceAddress,
    setIsServiceAndBillingAddressTheSame,
    setIsBillingAndPreviousBillingAddressTheSame,
    setAllowCreditCheck,
    setDataState,
    setInstallDate,
    setCreditCard,
    setACP,
    setIsBenefitQualifyingPerson,
    setShowCreditCheck,
    setNYB,
    setAdditionalArgs,
    setProgram,
    setCustomerWantsFusion,
    closeAlert,
    openAlert,
    updateCustomerPhoneNumber,
    updateCustomerLandLineNumber,
    updateTcPhone,
    setAllowBroadbandLink,
    setDishSubscriber,
    setReviewedLabel,
    setReadLabel,
    setCustomerDeclinedLabel,
    setPriorityPackage,
    setUserWantsNycaf,
    setDishBundleAccepted,
    setServiceAvailable,
    setCredit_card_fail_count,
    setCredit_card_lockout,
    setBroadbandLabelLink,
    setIsEPQCodeNewService,
    setEpqAttempt,
    setEpqAttemptBlockedId,

} = customerSlice.actions


export default customerSlice.reducer
