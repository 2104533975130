import {useSelector} from 'react-redux'
import {isMexico, useInputDataState, useLabels} from '../../functions'
import {Alert, Col, Form, Row} from 'react-bootstrap'
import Features from './Features'
import {FormCheck, FormLabel} from '../Utilities/Form'

export const ProbingQuestionsConsumer = () => {
    const labels = useLabels()
    const connections = useSelector(state => {
        if (isMexico(state.customer.data.program)) {
            return state.settings.connectionsMX
        }
        return state.settings.connections
    })
    const [connection, setConnection] = useInputDataState('connection')
    const program = useSelector(state => state.customer.data.program)
    const [streaming, setStreaming] = useInputDataState('streaming')
    const whyNewServiceReasons = useSelector(state => {
        return state.settings.whyNewServiceReasons
    })
    const [whyNewService, setWhyNewService] = useInputDataState('whyNewService')
    const isEPQCodeNewService = useSelector(state => state.customer.data.isEPQCodeNewService)

    return <>
        <Row>
            <Col>
                <Alert>
                    <h3>{labels.probingQuestions}</h3>
                    <h5>{labels.probingQuestionHelp}</h5>
                </Alert>
            </Col>
        </Row>
        <Row className="mb-3">
            {isEPQCodeNewService ?
                <Col>
                    <FormLabel required={true}>{labels.whyCustOrderingNewService}</FormLabel>
                    <Form.Select onChange={setWhyNewService} value={whyNewService}>
                        <option></option>
                        {whyNewServiceReasons.map(o => <option value={o} key={o}>{o}</option>)}
                    </Form.Select>
                </Col>
                : null
            }
        </Row>
        <Row className="mb-3">
            {isMexico(program) ?
                <Col>
                    <Form.Group>
                        <FormLabel bold={true}>{labels.businessVideoStreaming}</FormLabel>
                        <FormCheck type="radio" value="Usuario ocasional" checked={streaming === 'Usuario ocasional'}
                                   onChange={setStreaming}
                                   label="Usuario ocasional"/>
                        <FormCheck type="radio" value="Usuario frecuente" checked={streaming === 'Usuario frecuente'}
                                   onChange={setStreaming}
                                   label="Usuario frecuente"/>
                    </Form.Group>
                </Col>
                : null
            }
            <Col>
                <FormLabel bold={true}>{labels.howDoYouConnectToInternet}</FormLabel>
                <Form.Select onChange={setConnection} value={connection}>
                    <option></option>
                    {connections.map(o => <option value={o} key={o}>{o}</option>)}
                </Form.Select>
            </Col>
        </Row>

        <Features/>

    </>
}


