import {useDispatch, useSelector} from 'react-redux'
import {useInputDataState, useLabels} from '../../functions'
import {Alert, Button, Col, Form, Row} from 'react-bootstrap'
import {connect} from '../Notes/wrapper'
import './preview-order.scss'
import {sendPreviewOrder, validateEmail} from '../../state/customerAsyncActions'
import {validatePreviewOrder} from '../../state/validationSlice'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {useEffect} from 'react'
import {openAlert} from '../../state/customerSlice'
import {Email} from "../Utilities/GeneralComponents";

export const ChargesExplained = () => {
    const labels = useLabels()
    const [chargesExplained, setChargesExplained] = useInputDataState('chargesExplained')

    return <Row className="mb-3">
        <Col>
            <FormLabel bold={true} required={true}>{labels.customerAcceptedTAndC}</FormLabel>
            <FormCheck type="radio" value="Yes" label={labels.yesOption} checked={chargesExplained === 'Yes'}
                       onChange={setChargesExplained}/>
            <FormCheck type="radio" value="No" label={labels.noOption} checked={chargesExplained === 'No'}
                       onChange={setChargesExplained}/>
        </Col>
    </Row>
}


export const PreviewOrder = () => {
    const labels = useLabels()
    const sendPreviewState = useSelector(state => state.customer.sendPreviewState)
    const sendPreviewLink = useSelector(state => state.customer.sendPreviewLink)
    const [tcPhone, setTCPhoneNumber] = useInputDataState('tcPhone')
    const phoneNumber = useSelector(state => state.customer.data.serviceAddress.phoneNumber)
    const cartState = useSelector(state => state.customer.cartState)
    const tcEmail = useSelector(state => state.customer.data.tcEmail)
    const nonPCI = useSelector(state => state.settings.nonPCI)
    const dispatch = useDispatch()


    const onClick = async () => {
        const ret = await dispatch(validatePreviewOrder())
        if (ret.payload !== null) {
            if (tcEmail && tcEmail === 'nobody@hughes.com' ) {
                dispatch(openAlert(labels.invalidEmailAddress))
                return
            }
            if (tcEmail !== "") {
                const response = await dispatch(validateEmail())
                if (!response.payload || !response.payload.emailverified) {
                    dispatch(openAlert(labels.invalidEmailAddress))
                    return
                }
            }

            dispatch(sendPreviewOrder())
        }
    }

    useEffect(() => {
        if (!tcPhone && phoneNumber) {
            setTCPhoneNumber({target: {value: phoneNumber}})
        }
    }, [])


    return <div className="preview-order">
        <Row className="mb-3 mt-3">
            <Col>
                <Alert>
                    <h3>
                        {labels.orderPreview}
                    </h3>
                </Alert>
            </Col>
        </Row>
        <Row className="mt-3">
            <Email md={5}/>
            <Col md={5}>
                <FormLabel bold={true}>{labels.addressPhoneNumber}</FormLabel>
                <Form.Control value={tcPhone} onChange={setTCPhoneNumber}/>
            </Col>
        </Row>
        {!nonPCI && sendPreviewLink ? <>
            <br/>
            <Row>
                <Col>
                    <a href={sendPreviewLink} target="_blank">{labels.sendPreviewLink}</a>
                </Col>
            </Row>
        </> : null}
        <Row className="mt-3 mb-3">
            <Col md={{span: 4, offset: 5}}>
                <Button onClick={onClick} disabled={cartState !== 'done'}>
                    {sendPreviewState !== 'pending' ? labels.sendOrderPreview : labels.sendingOrderPreview}
                </Button>
            </Col>
        </Row>
        <ChargesExplained/>
    </div>
}


export default connect(PreviewOrder, 'preview-order')
