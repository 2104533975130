import {Col, Form, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {FormLabel, FormCheck} from '../Utilities/Form'
import {setNYB} from '../../state/customerSlice'
import {foreNYB} from '../../state/customerAsyncActions'
import {connect} from '../Notes/wrapper'
import {useLabels} from '../../functions'

export const NYB = () => {
    const labels = useLabels()
    const nybOffer = useSelector(state => state.customer.nybOffer)
    const nybOfferStatus = useSelector(state => state.customer.nybOfferStatus)
    const dispatch = useDispatch()

    const onChange = (e) => {
        const value = e.target.value
        if (value === 'Yes') {
            dispatch(foreNYB(value))
        }
        dispatch(setNYB(value))
    }

    return <Row className="mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true}>{labels.nybAddressQualifies}</FormLabel>
                <FormCheck disabled={nybOffer === 'Yes' || nybOfferStatus === 'pending'} type="radio" value="Yes"
                            label={labels.yesOption} checked={nybOffer === 'Yes'} onChange={onChange}/>
                <FormCheck disabled={nybOffer === 'Yes' || nybOfferStatus === 'pending'} type="radio" value="No"
                            label="No" checked={nybOffer === 'No'} onChange={onChange}/>
            </Form.Group>
        </Col>
    </Row>
}

export default connect(NYB, 'nyb')
