import {useState} from 'react'
import {connect} from '../Notes/wrapper'
import {Alert, Button, Col, Form, Row} from 'react-bootstrap'
import {runAndShowLoader, useLabels, useInput} from '../../functions'
import {FormLabel} from '../Utilities/Form'
import {useDispatch} from 'react-redux'
import {sendDealerEmail} from '../../state/customerAsyncActions'



export const DealerForm = () => {
    const labels = useLabels()
    const [dealerName, setDealerName] = useInput()
    const [dealerPhoneNumber, setDealerPhoneNumber] = useInput()
    const [dealerState, setDealerState] = useInput()
    const [sent, setSent] = useState(false)
    const dispatch = useDispatch()

    const onClick = async () => {
        await runAndShowLoader(() => {
            return dispatch(sendDealerEmail({dealerName, dealerPhoneNumber, dealerState}))
        }, dispatch)
        setSent(true)
    }

    return <>
        <Alert variant="success">{labels.emailSent}</Alert>
        <Row className="mb-3 mt-3">
            <Col>
                <FormLabel bold={true} required={true}>{labels.dealerName}</FormLabel>
                <Form.Control disabled={sent} value={dealerName} onChange={setDealerName}/>
            </Col>
        </Row>
        <Row className="mb-3 mt-3">
            <Col>
                <FormLabel bold={true} required={true}>{labels.dealerPhoneNumber}</FormLabel>
                <Form.Control disabled={sent} value={dealerPhoneNumber} onChange={setDealerPhoneNumber}/>
            </Col>
        </Row>
        <Row className="mb-3 mt-3">
            <Col>
                <FormLabel bold={true} required={true}>{labels.dealerState}</FormLabel>
                <Form.Control disabled={sent} value={dealerState} onChange={setDealerState}/>
            </Col>
        </Row>
        <Row>
            <Col md={{span: 4, offset: 4}}>
                <Button onClick={onClick}>{labels.dealerSendEmail}</Button>
            </Col>
        </Row>
    </>
}

export default connect(DealerForm, 'mx-dealer')
