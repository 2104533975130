import {useDispatch, useSelector} from 'react-redux'
import {getNote, useInputDataState, useLabels} from '../../functions'
import {Col} from "react-bootstrap";
import {FormCheck, FormLabel} from "../Utilities/Form";
import {getSectionContent} from "../Notes";
import {setAppLoadingState} from "../../state/settingsSlice";
import {setDataState} from "../../state/customerSlice";
import { writeToRedis} from "../../state/customerAsyncActions";


export const Dish = () => {
    const notes = useSelector(state => state.settings.notes)
    const data = useSelector(state => state.customer.data)
    const wishToHearDish = useSelector(state => state.customer.data.wishToHearDish)
    const [transferToDish, setTransferToDish] = useInputDataState('transferToDish')
    const labels = useLabels()
    const dispatch = useDispatch()

    const handleTransfer = async (e) => {
        dispatch(setAppLoadingState('pending'))
        setTransferToDish(e)
        await dispatch(writeToRedis())
        dispatch(setAppLoadingState(''))
    }

    let transferToDishComponent
    if (wishToHearDish === 'Yes') {
        transferToDishComponent = <Col md={6}>
            <FormLabel required={true}>{labels.transferToDishQuestion}</FormLabel>
            <FormCheck type="radio" name="transferToDish" value="Yes" label="Yes"
                       onChange={handleTransfer}
                       checked={transferToDish === 'Yes'}/>
            <FormCheck type="radio" name="transferToDish" value="No" label="No"
                       onChange={handleTransfer}
                       checked={transferToDish === 'No'}/>
        </Col>
    }


    const note = getNote(notes, 'dish-v2')


    return <>
        {transferToDishComponent}
        {note && transferToDish === 'Yes' ? getSectionContent(note, data) : null}
        {transferToDish === 'No' ? labels.doNotTransferToDish : null}
    </>
}


