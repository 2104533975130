import {useEffect} from 'react'
import {
    checkExistingCases, createNewCase, fetchCase, getPlansVisualPage, reAssignCase,
} from '../state/customerAsyncActions'
import {setAdditionalArgs, setProgram} from '../state/customerSlice'
import {useDispatch, useSelector} from 'react-redux'
import {BeatLoader} from 'react-spinners'
import {Header} from './Header'
import {CustomerForm} from './CustomerForm'
import {Alert, Button, Col, Container, Form, Row, Table} from 'react-bootstrap'
import Order from './Order'
import {Notes} from './Notes'
import {
    detectLang,
    getSectionNotes,
    getStringTranslations,
    login,
    setAppLoadingState,
    setNonPCI,
    setLangScript,
    setNotesSection,
    setOrderType,
    setShowLoginForm,
    setSimpleToken,
    setSSOToken, hideScriptButtons
} from '../state/settingsSlice'
import {useInputState, useLabels} from '../functions'
import {FormLabel} from './Utilities/Form'
import './app-loader.scss'
import {AlertPopup} from './Utilities/GeneralComponents'

const args = {}


async function onAfterCaseLoad(dispatch, caseData) {
    dispatch(setNotesSection({script: caseData?.script, mediaType: caseData?.mediaType, section: 'greetings'}))
    if (caseData?.packages) {
        await dispatch(getPlansVisualPage())
    }
}

async function _fetchCase(caseId, program, dispatch) {
    const ret = await dispatch(fetchCase({caseId, program}))
    let caseData = ret.payload
    await onAfterCaseLoad(dispatch, caseData)
    return caseData
}

async function loadForm(dispatch, searchParams) {
    let caseData
    if (searchParams.has('program')) {
        dispatch(setProgram(searchParams.get('program')))
    }
    await dispatch(getSectionNotes())
    if (searchParams.has('non-pci')) {
        dispatch(setNonPCI(true))
    }
    if (searchParams.has('caseid')) {
        caseData = await _fetchCase(searchParams.get('caseid'), searchParams.get('program'), dispatch)
        dispatch(setOrderType('new'))
    } else {
        for (const [key, value] of searchParams.entries()) {
            args[key] = value
        }
        dispatch(setAdditionalArgs(args))
        dispatch(setAppLoadingState('pending'))
        const ret = await dispatch(checkExistingCases(args))
        if (!Array.isArray(ret.payload) || ret.payload.length === 0) {
            caseData = (await dispatch(createNewCase(args))).payload
            dispatch(setNotesSection({script: caseData?.script, mediaType: caseData?.mediaType, section: 'greetings'}))
        }
        dispatch(setAppLoadingState(''))
    }
    if (!caseData) {
        return
    }
    const {language, program, script} = caseData
    dispatch(detectLang({language, program}))
    dispatch(setLangScript(script))
}

const loadApp = async (dispatch) => {
    await dispatch(getStringTranslations())
    const searchParams = new URLSearchParams(window.location.search)

    const language = searchParams.get('language')
    const program = searchParams.get('program')
    dispatch(detectLang({language, program}))

    if (searchParams.has('hideScriptButtons')){
        dispatch(hideScriptButtons())
    }

    if (searchParams.has('ssoToken')) {
        dispatch(setSSOToken(searchParams.get('ssoToken')))
        await loadForm(dispatch, searchParams)
    } else if (searchParams.has('simpleToken') && searchParams.get('simpleToken') !== 'undefined' ) {
        dispatch(setSimpleToken(searchParams.get('simpleToken')))
        await loadForm(dispatch, searchParams)
    } else {
        if (window.localStorage.creds) {
            const creds = JSON.parse(window.localStorage.creds)
            const loginFunc = getLoginFunc({...creds, dispatch})
            const r = await loginFunc()
            if (r) {
                return
            }
        }
        dispatch(setShowLoginForm(true))
    }
}

const ErrorPage = (props) => {
    return (<Container fluid={true}>
        <Row className="error">
            <Col sm={12} md={6}>
                <span>
                    {props.message}
                </span>
            </Col>
        </Row>
    </Container>)
}

const getLoginFunc = ({dispatch, username, password}) => async () => {
    const ret = await dispatch(login({username, password}))
    const [ok] = ret.payload
    if (ok) {
        window.localStorage.creds = JSON.stringify({username, password})
        const searchParams = new URLSearchParams(window.location.search)
        await loadForm(dispatch, searchParams)
    }

    return ok
}

const LoginForm = () => {
    const [username, setUsername] = useInputState('')
    const [password, setPassword] = useInputState('')
    const loginError = useSelector(state => state.settings.loginError)
    const loginState = useSelector(state => state.settings.loginState)
    const dispatch = useDispatch()

    const onLogin = getLoginFunc({dispatch, username, password})

    return <>
        {loginError !== '' ? <Row className="mt-3 mb-3">
            <Col md={{span: 4, offset: 4}}>
                <Alert>{loginError}</Alert>
            </Col>
        </Row> : null}
        <Row className="mt-3">
            <Col className="login-form" md={{span: 4, offset: 4}}>
                <Form.Group>
                    <FormLabel bold={true}>Username</FormLabel>
                    <Form.Control value={username} onChange={setUsername}/>
                </Form.Group>
                <Form.Group>
                    <FormLabel bold={true}>Password</FormLabel>
                    <Form.Control type="password" value={password} onChange={setPassword}/>
                </Form.Group>
                <Button className="mt-3" onClick={onLogin}
                        disabled={loginState === 'pending' || !username || !password}>
                    Submit
                </Button>
            </Col>
        </Row>
    </>
}

const CaseSelector = () => {
    const labels = useLabels()
    const existingCases = useSelector(state => state.customer.cases)
    const dispatch = useDispatch()

    const onSelect = async (cs) => {
        dispatch(setAppLoadingState('pending'))
        const ret = await dispatch(reAssignCase({args, data: cs}))
        const caseData = ret.payload
        await onAfterCaseLoad(dispatch, caseData)
        dispatch(setLangScript(caseData?.script))
        dispatch(setOrderType('new'))
        dispatch(setAppLoadingState(''))
    }

    const onCreateNewCase = async () => {
        dispatch(setAppLoadingState('pending'))
        const caseData = (await dispatch(createNewCase(args))).payload
        dispatch(setNotesSection({script: caseData?.script, mediaType: caseData?.mediaType, section: 'greetings'}))
        dispatch(setOrderType('new'))
        dispatch(setAppLoadingState(''))
    }

    return <>
        <Row className="mt-5">
            <Col>
                <h5>{labels.caseSelectorInfo}</h5>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <Button onClick={onCreateNewCase}>{labels.createNewCase}</Button>
            </Col>
        </Row>
        <Row className="mt-5 mb-5 cases-table">
            <Col>
                <Table hover>
                    <thead>
                    <tr>
                        <th>{labels.caseId}</th>
                        <th>{labels.addressFirstName}</th>
                        <th>{labels.addressLastName}</th>
                        <th>{labels.addressPhoneNumber}</th>
                        <th>Script</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {existingCases.map(cs => <tr key={cs.caseId}>
                        <td>{cs.caseId}</td>
                        <td>{cs.serviceAddress?.firstName}</td>
                        <td>{cs.serviceAddress?.lastName}</td>
                        <td>{cs.phoneNumber}</td>
                        <td>{cs.script}</td>
                        <td>
                            <Button onClick={() => onSelect(cs)}>{labels.copyAndCreateCase}</Button>
                        </td>
                    </tr>)}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </>
}

let loadAppCalled = false

export const AppLoader = () => {
    const applicationError = useSelector(state => state.customer.applicationError)
    const orderId = useSelector(state => state.customer.data.orderId)
    const isAgentNoteOpen = useSelector(state => state.settings.isAgentNoteOpen)
    const appLoadingState = useSelector(state => state.settings.appLoadingState)
    const showLoginForm = useSelector(state => state.settings.showLoginForm)
    const existingCases = useSelector(state => state.customer.cases)


    const dispatch = useDispatch()
    useEffect(() => {
        if (loadAppCalled) {
            return
        }
        loadAppCalled = true
        loadApp(dispatch)
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [])

    function getComponent() {
        if (showLoginForm) {
            return <LoginForm/>
        }

        if (existingCases.length > 0) {
            return <CaseSelector/>
        }

        let component
        if (applicationError) {
            component = <ErrorPage message={applicationError}/>
        } else if (orderId) {
            component = <Order/>
        } else {
            component = <CustomerForm/>
        }
        if (isAgentNoteOpen) {
            return <Row>
                <Col md={6} as="main" className="form-panel">{component}</Col>
                <Notes/>
            </Row>
        } else {
            return <Row as="main" className="form-panel">
                {component}
            </Row>
        }
    }


    return (<>
        <div className="loading" style={{display: appLoadingState === 'pending' ? 'block' : ''}}>
            <div>
                <Row className="mt-3">
                    <Col md={{span: 2, offset: 5}}>
                        <div className="loading-container">
                            <BeatLoader color="#0092c5" size={40}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <Header/>
        <Container className="main-container" fluid>
            {getComponent()}
            <AlertPopup/>
        </Container>
    </>)
}

