import {useDispatch, useSelector} from 'react-redux'
import {Alert, Col, Form, Row} from 'react-bootstrap'
import {getSectionNotes, setLang, setOrderType} from '../../state/settingsSlice'
import {connect} from '../Notes/wrapper'
import './greetings.scss'
import {isCenturyLinkAcquisition, isConsumer, isDealer, isMexico, useInputDataState, useLabels} from '../../functions'
import {centuryLinkPhone1, scriptCenturyLink, scriptFrontier} from '../../constants'
import {setDataState} from '../../state/customerSlice'
import {FormLabel, FormCheck} from '../Utilities/Form'
import {HowDidYouHear} from '../Utilities/GeneralComponents'

export function Greetings() {
    const labels = useLabels()
    const currentLang = useSelector(state => state.settings.lang)
    const orderType = useSelector(state => state.settings.orderType)
    const mediaType = useSelector(state => state.customer.data.mediaType)
    const program = useSelector(state => state.customer.data.program)
    const [promoCode, setPromoCode] = useInputDataState('promoCode')
    const [whatsapp, setWhatsapp] = useInputDataState('whatsapp')
    const spanish = useSelector(state => {
        if (isMexico(state.customer.data.program)) {
            return 'es-mx'
        }
        return 'es'
    })
    const dispatch = useDispatch()

    const onChange = (e) => {
        dispatch(setOrderType(e.target.value))
    }
    const changeLang = function (e) {
        dispatch(setLang(e.target.value))
        dispatch(getSectionNotes())
    }

    let component
    if (orderType === 'new') {
        component = <>
            <HowDidYouHear/>
            {isMexico(program) ? <Row className="mb-3">
                <Col>
                    <FormLabel bold={true}>{labels.thisPersonComesFromWhatsApp}</FormLabel>
                    <FormCheck type="radio" label="Si" value="Si" checked={whatsapp === 'Si'} onChange={setWhatsapp}/>
                    <FormCheck type="radio" label="No" value="No" checked={whatsapp === 'No'} onChange={setWhatsapp}/>
                </Col>
            </Row> : null}
            {isDealer(mediaType) ? <>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group>
                                <FormLabel bold={true}>{labels.promoCode}</FormLabel>
                                <Form.Control value={promoCode} onChange={setPromoCode}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </>
                : null
            }
            <Referrals/>

        </>
    }

    return (
        <Row className="greetings">
            <Row>
                <Col md={4} sm={12}>
                    {/*<img src={consumerLogo} alt=""/>*/}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Alert>
                            <h5>{labels.lang}</h5>
                        </Alert>
                        <FormCheck type="radio" value="en" checked={['en', 'en-external'].includes(currentLang)} label="English"
                                   onChange={changeLang}/>
                        <FormCheck type="radio" value={spanish} checked={currentLang === spanish} label="Español"
                                   onChange={changeLang}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Alert>
                        <h5>
                            {labels.greetingsAlreadyCustomer}
                        </h5>
                    </Alert>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <FormCheck value="existing" checked={orderType === 'existing'} onChange={onChange} name="orderType"
                               type="radio"
                               label={labels.greetingsExistingCustomer}/>
                    <FormCheck checked={orderType === 'new'} value="new" onChange={onChange} name="orderType"
                               type="radio"
                               label={labels.greetingsNewOrder}/>
                    {isMexico(program) ?
                        <FormCheck label={labels.greetingsDealer} onChange={onChange} name="orderType" value="dealer"
                                   type="radio"/> : null}
                </Col>
            </Row>
            {component}
        </Row>
    )
}


export const Referrals = () => {
    const script = useSelector(state => state.customer.data.script)

    if (!isConsumer(script) || script === null) {
        return <></>
    }

    if (script.toLowerCase() === scriptCenturyLink.toLowerCase()) {
        return <CenturyLink/>
    }

    if (script.toLowerCase() === scriptFrontier.toLowerCase()) {
        return <Frontier/>
    }
}

export const CenturyLink = () => {
    const labels = useLabels()
    const dnis = useSelector(state => state.customer.data.tfn)
    let key = 'reserve7'
    if (!isCenturyLinkAcquisition(dnis)) {
        key = 'reserve3'
    }
    const [dynamicReserve, setDynamicReserve] = useInputDataState(key)

    const dispatch = useDispatch()


    if (dnis === centuryLinkPhone1) {
        dispatch(setDataState({key: 'reserve3', value: 'centurylinkWebsite'}))
        return <></>
    }

    return <Row className="mb-3 mt-3">
        <Col>
            <Form.Group>
                <FormLabel
                    bold={true}>{isCenturyLinkAcquisition(dnis) ? labels.fuseTransfer : labels.centuryLinkTransfer}</FormLabel>
                <Form.Control onChange={setDynamicReserve} value={dynamicReserve}/>
            </Form.Group>
        </Col>
    </Row>
}


export const Frontier = () => {
    const labels = useLabels()
    const [reserve7, setReserve7] = useInputDataState('reserve7')
    const [reserve3, setReserve3] = useInputDataState('reserve3')

    return <>
        <Row className="mb-3">
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.frontierCRIS}</FormLabel>
                    <Form.Control value={reserve3} onChange={setReserve3}/>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.frontierFiled}</FormLabel>
                    <Form.Control value={reserve7} onChange={setReserve7}/>
                </Form.Group>
            </Col>
        </Row>
    </>
}
export default connect(Greetings, 'greetings')
