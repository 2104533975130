import {useDispatch, useSelector} from 'react-redux'
import {Alert, Button, Col, Row} from 'react-bootstrap'
import {getTotalCalculations, recordFusionCode} from '../../state/customerAsyncActions'
import {connect} from '../Notes/wrapper'
import {setAppLoadingState} from '../../state/settingsSlice'
import {isBusiness, useIsMexico, useIsPR, useLabels} from '../../functions'
import './cart.scss'
import {openAlert} from '../../state/customerSlice'


const CartSection = (props) => {
    const labels = useLabels()
    const formatPrice = (price) => {
        if (price < 0) {
            return `$(${price})`
        }
        return `$${price}`
    }

    const total = Number(Number(props.total).toFixed(2))
    return <div className="section">
        <Row>
            <Col>
                <h4>{props.title}</h4>
            </Col>
        </Row>
        <div className="section-items">
            {props.items.map(item => {
                let className = 'item'
                if (item.Price < 0) {
                    className += ' credit'
                }
                return <Row key={item.Item}>
                    <Col xs={8} sm={6} md={8}>
                        <span className={className}>{item.Item}</span>
                    </Col>
                    <Col xs={4} sm={6} md={4}>
                        <span className={className}>{formatPrice(item.Price)}</span>
                    </Col>
                </Row>
            })}
        </div>
        <div className="section-total">
            <Row>
                <Col sm={6} md={8}>
                    <span>
                        {labels.monthlyBill}<br/>
                        <span>({labels.notTaxesIncluded})</span>
                    </span>
                </Col>
                <Col sm={6} md={4}>
                    <span>{formatPrice(total)}</span>
                </Col>
            </Row>
        </div>
    </div>
}

export const Cart = () => {
    const labels = useLabels()
    const cart = useSelector(state => state.customer.cart)
    const cartState = useSelector(state => state.customer.cartState)
    const dispatch = useDispatch()
    const plan = useSelector(state => state.customer.data.plan)
    const isMX = useIsMexico()
    const isPR = useIsPR()
    const installDate = useSelector(state => state.customer.data.installDate)
    const explainedPricing = useSelector(state => state.customer.data.explainedPricing)
    const resId = useSelector(state => state.customer.data.resId)
    const continuity = useSelector(state => state.customer.data.continuity)
    const script = useSelector(state => state.customer.data.script)
    const contactConsentBroadband = useSelector(state => state.customer.data.contactConsentBroadband)

    if (!plan) {
        return <></>
    }

    const onClick = async () => {
        if (!isBusiness(script) && !contactConsentBroadband && !isMX) {
            dispatch(openAlert(labels.consentToContactBroadbandRequired))
            return null
        }

        if (!isMX && installDate !== 'Bypass' && !resId) {
            dispatch(openAlert(labels.pleaseDoTheReservation))
            return
        }

        if (!isMX && !isPR && !explainedPricing && continuity !== 'Continuity') {
            dispatch(openAlert(labels.pleaseExplainPricing))
            return
        }

        dispatch(setAppLoadingState('pending'))
        await dispatch(getTotalCalculations())
        dispatch(recordFusionCode())
        dispatch(setAppLoadingState(''))
    }

    let cartComponents
    let achDisclaimerCopyComponent
    if (continuity !== 'Continuity' && !isPR) {
        achDisclaimerCopyComponent = <>
            <Row>
                <Col sm={{span: 8}} md={{span: 6}}>
                    <h6>{labels.achDisclaimer}</h6>
                </Col>
            </Row>
        </>
    }
    if (cartState && cartState === 'done') {
        cartComponents = <>
            <Row className="mt-3">
                <Col>
                    <Alert>
                        <h3>{labels.cart}</h3>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CartSection title={labels.cartPlanFees} items={cart.feesAndCredits}
                                 total={cart.totalFeesAndCredits}/>
                    <CartSection title={labels.cartOtherFees} items={cart.otherFeesAndCredits}
                                 total={cart.totalOtherFeesAndCredits}/>
                    <CartSection title={labels.cartOneTimeFees} items={cart.oneTimeFeesAndCredits}
                                 total={cart.totalOneTimeFeesAndCredits}/>
                    <br/>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={{span: 6}} md={{span: 4}}>
                    <h4 className="total">{labels.cartTotal} ${Number(cart.total).toFixed(2)}</h4>
                </Col>
            </Row>
            {achDisclaimerCopyComponent}


        </>
    }

    return <div className="cart">
        {cartComponents}
        <Row className="mt-5 mb-3">
            <Col sm={12} md={{span: 2, offset: 5}}>
                <Button onClick={onClick}>
                    {cartState === 'done' ? labels.reloadCart : labels.loadCart}
                </Button>
            </Col>
        </Row>
    </div>
}

export default connect(Cart, 'cart')
