import {useDispatch, useSelector} from 'react-redux'
import {isMexico, useInputDataState, useIsPR, useLabels} from '../../functions'
import {writeExtraChoices} from '../../state/customerAsyncActions'
import {Col, Form, Row} from 'react-bootstrap'
import {HowManyUsers} from './general'
import {FormCheck, FormLabel} from '../Utilities/Form'

export const FeaturesBusiness = () => {
    const labels = useLabels()
    const [satPackageChoice, setSatPackageChoice] = useInputDataState('satPackageChoice')
    const [useHomeBusinessChoice, setUseHomeBusinessChoice] = useInputDataState('useHomeBusinessChoice')
    const program = useSelector(state => state.customer.data.program)
    const [someonePlayVideoGames, setSomeonePlayVideoGames] = useInputDataState('someonePlayVideoGames')
    const tvServiceTypesOptions = useSelector(state => state.settings.tvServiceTypes)
    const [tvServiceType, setTvServiceType] = useInputDataState('tvServiceType')
    const [planToKeepTV, setPlanToKeepTV] = useInputDataState('planToKeepTV')
    const [workFromHome, setWorkFromHome] = useInputDataState('workFromHome')
    const [vpnUsed, setVpnUsed] = useInputDataState('vpnUsed')
    const [securityUsed, setSecurityUsed] = useInputDataState('securityUsed')
    const [buildingType, setBuildingType] = useInputDataState('buildingType')
    const dispatch = useDispatch()
    const isPR1 = useIsPR()

    const onSatPackageChoice = (e) => {
        setSatPackageChoice(e)
        dispatch(writeExtraChoices())
    }


    let packageChoicesComponent
    let packageWhatTypeTVServiceComponent
    let packageOnlineVideoGamesComponent
    let packageWorkFromHomeComponent
    let packageSecurityUsedComponent
    let businessBuildingTypeComponent
    let useHomeBusinessQuestionComponent

    let planToKeepTVComponent
    if (['DISH', 'DTV', 'Cable TV'].includes(tvServiceType)) {
        planToKeepTVComponent = <Col md={6}>
            <FormLabel required={true}>{labels.planToKeepTVQuestion}</FormLabel>
            <FormCheck type="radio" name="planToKeepTV" value="Yes" label="Yes" onChange={setPlanToKeepTV}
                       checked={planToKeepTV === 'Yes'}/>
            <FormCheck type="radio" name="planToKeepTV" value="No" label="No" onChange={setPlanToKeepTV}
                       checked={planToKeepTV === 'No'}/>
        </Col>
    }

    let vpnUsedComponent
    if (workFromHome === 'Y') {
        vpnUsedComponent = <Col md={6}>
            <FormLabel required={true}>{labels.vpnUsedQuestion}</FormLabel>
            <FormCheck type="radio" label={labels.yesOption} value="Y" checked={vpnUsed === 'Y'}
                       onChange={setVpnUsed}/>
            <FormCheck type="radio" label="No" value="N" checked={vpnUsed === 'N'}
                       onChange={setVpnUsed}/>
        </Col>
    }

    if (isMexico(program)) {
        useHomeBusinessQuestionComponent = <Row className="mb-3">
            <Col>
                <FormLabel bold={true}>{labels.useHomeBusinessQuestion}</FormLabel>
                <FormCheck type="radio" name="useHomeBusinessChoice" checked={useHomeBusinessChoice === 'Home'}
                           label='Casa' value='Home' onChange={setUseHomeBusinessChoice}/>
                <FormCheck type="radio" name="useHomeBusinessChoice" checked={useHomeBusinessChoice === 'Office'}
                           label='Oficina (Pyme o emprendimiento)' value='Office' onChange={setUseHomeBusinessChoice}/>
            </Col>
        </Row>
    } else if (isPR1) {
        packageChoicesComponent = <Row className="mb-3">
            <Col>
                <FormLabel bold={true}>{labels.satPackageChoice}</FormLabel>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend1And2}
                           label={labels.recommend1And2} value={labels.recommend1And2} onChange={onSatPackageChoice}/>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend3And4}
                           label={labels.recommend3And4} value={labels.recommend3And4} onChange={onSatPackageChoice}/>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend5And10}
                           label={labels.recommend5And10} value={labels.recommend5And10} onChange={onSatPackageChoice}/>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend11And15}
                           label={labels.recommend11And15} value={labels.recommend11And15}
                           onChange={onSatPackageChoice}/>
            </Col>
        </Row>
    } else if (!isMexico(program) && !isPR1) {


        packageWhatTypeTVServiceComponent = <Row className="mb-3">
            <Col md={6}>
                <FormLabel required={true}>{labels.whatTypeTVService}</FormLabel>
                <Form.Select value={tvServiceType} onChange={setTvServiceType}>
                    <option></option>
                    {tvServiceTypesOptions.map(item => <option key={item} value={item}>{item}</option>)}
                </Form.Select>
            </Col>
            {planToKeepTVComponent}
        </Row>

        packageWorkFromHomeComponent = <Row className="mb-3">
            <Col md={6}>
                <FormLabel required={true}>{labels.isHomeBusiness}</FormLabel>
                <FormCheck type="radio" label={labels.yesOption} value="Y" checked={workFromHome === 'Y'}
                           onChange={setWorkFromHome}/>
                <FormCheck type="radio" label="No" value="N" checked={workFromHome === 'N'}
                           onChange={setWorkFromHome}/>
            </Col>
            {vpnUsedComponent}
        </Row>

        businessBuildingTypeComponent = <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel bold={true} required={true}>{labels.businessBuildingType}</FormLabel>
                    <FormCheck value="Commercial Building" label="Commercial Building"
                               checked={buildingType === 'Commercial Building'} onChange={setBuildingType}/>
                    <FormCheck value="Residential Location" label="Home"
                               checked={buildingType === 'Residential Location'} onChange={setBuildingType}/>
                </Form.Group>
            </Col>
        </Row>

        packageSecurityUsedComponent = <Row className="mb-3">
            <Col md={6}>
                <FormLabel required={true}>{labels.securityUsedQuestion}</FormLabel>
                <FormCheck type="radio" label={labels.yesOption} value="Y" checked={securityUsed === 'Y'}
                           onChange={setSecurityUsed}/>
                <FormCheck type="radio" label="No" value="N" checked={securityUsed === 'N'}
                           onChange={setSecurityUsed}/>
            </Col>
        </Row>

    }


    packageOnlineVideoGamesComponent = <Col md={6}>
        <Form.Group>
            <FormLabel bold={true}>{labels.onlineVideoGameQuestion}</FormLabel>
            <FormCheck type="radio" label={labels.yesOption} value="Y" checked={someonePlayVideoGames === 'Y'}
                       onChange={setSomeonePlayVideoGames}/>
            <FormCheck type="radio" label="No" value="N" checked={someonePlayVideoGames === 'N'}
                       onChange={setSomeonePlayVideoGames}/>
        </Form.Group>
    </Col>

    return <>

        {packageChoicesComponent}

        <Row className="mb-3">
            <HowManyUsers md={6}/>
            {packageWorkFromHomeComponent}
        </Row>
        {useHomeBusinessQuestionComponent}
        {businessBuildingTypeComponent}
        {packageWhatTypeTVServiceComponent}
        {packageOnlineVideoGamesComponent}

        {packageSecurityUsedComponent}


    </>
}

