import {Col, Form} from 'react-bootstrap'
import {useInputDataState, useLabels} from '../../functions'
import {FormLabel} from '../Utilities/Form'

export const HowManyUsers = (props) => {
    const labels = useLabels()
    const [users, setUsers] = useInputDataState('users')

    return <Col {...props}>
{/*required={true}*/}
        <FormLabel bold={true} >{labels.howManyUsersInHousehold}</FormLabel>

        <Form.Select value={users} onChange={setUsers}>
            <option></option>
            {['1', '2', '3', '4', '5', '>6'].map(n => <option key={n} value={n}>{n}</option>)}
        </Form.Select>
    </Col>
}
