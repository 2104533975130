export const urlHost = process.env.REACT_APP_HOST_URL
export const environment = process.env.REACT_APP_ENV
export const scriptBusiness = 'business'
export const scriptConsumer = 'consumer'
export const scriptMexico = 'mexico'
export const scriptConsumerResidential = 'consumer residential'
export const scriptFrontierWholesale = 'frontierwholesale'
export const scriptZiply = 'ziply'
export const scriptCenturyLink = 'CenturyLink'
export const scriptFrontier = 'Frontier'
export const scriptWindstream = 'Windstream'
export const scriptClearlink = 'Clearlink'
export const scriptArchenia = 'Archenia'
export const scriptPowersetter = 'Powersetter'
export const scriptAragon = 'Aragon'
export const scriptRVEBB = 'RV EBB'
export const scriptHRSAGeneric = 'HRSA Generic'
export const scriptBrightspeed = 'Brightspeed'
export const scriptHRSA = 'HRSA'

export const mediaTypeDealer = 'dealer'

export const mediaTypeBroadcast2 = 'broadcast2'

export const programUSA = 'usa'
export const programMexico = 'mexico'

export const regionPR = 'pr'

export const centuryLinkPhone1 = '8444119841'

export const failEPQCode = '1'

export const programNonPCI = 'external'
