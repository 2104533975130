import {Provider} from 'react-redux'
import store from './state/store'
import {AppLoader} from './components/AppLoader'

function App() {
    return (
        <Provider store={store}>
            <AppLoader/>
        </Provider>
    )
}

export default App;
