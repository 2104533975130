import {AddressCollection} from '../AddressCollection'
import {Alert, Button, Col, Row} from 'react-bootstrap'
import {runAndShowLoader, useLabels} from '../../functions'
import {connect} from '../Notes/wrapper'
import {useDispatch} from 'react-redux'
import {sendMXNoServiceInfo} from '../../state/customerAsyncActions'
import {Email} from '../Utilities/GeneralComponents'
import {useState} from 'react'

export const MXNoService = () => {
    const labels = useLabels()
    const dispatch = useDispatch()
    const [success, setSuccess] = useState(null)

    const onClick = () => {
        runAndShowLoader(async () => {
            const ret = await dispatch(sendMXNoServiceInfo())
            setSuccess(ret.payload)
        }, dispatch)
    }

    return <>
        <AddressCollection showPhoneNumber={true}/>
        <Row className="mb-3">
            <Email/>
        </Row>
        <Row className="mb-3 mt-3">
            <Col md={{span: 3, offset: 5}}>
                <Button onClick={onClick}>{labels.sendFormNoService}</Button>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                {
                    success !== null ? <Alert
                        variant={success ? 'success' : 'danger'}>
                        {success ? labels.noServiceSuccess : labels.noServiceError}</Alert> : null
                }
            </Col>
        </Row>
    </>
}


export default connect(MXNoService, 'mx-no-service')
