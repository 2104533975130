import {useDispatch, useSelector} from 'react-redux'
import {Col, Form, Row} from 'react-bootstrap'
import {AddressCollection} from '../AddressCollection'
import {setBillingAddress, setIsServiceAndBillingAddressTheSame} from '../../state/customerSlice'
import {useLabels} from '../../functions'
import {FormLabel, FormCheck} from '../Utilities/Form'

export const BillingAddressCollection = (props) => {
    const isServiceAndBillingAddressTheSame = useSelector(state => state.customer.data.isServiceAndBillingAddressTheSame)
    const labels = useLabels()
    const serviceAddress = useSelector(state => state.customer.data.serviceAddress)
    const dispatch = useDispatch()
    let component = null
    if (!isServiceAndBillingAddressTheSame) {
        component = <AddressCollection type="billing" showOverride={props.showOverride}/>
    }

    const onChange = (e) => {
        const newVal = 'Yes' === e.target.value
        dispatch(setIsServiceAndBillingAddressTheSame(newVal))
        if (newVal) {
            dispatch(setBillingAddress(serviceAddress))
        }
    }

    return <>
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel bold={true}>{labels.isServiceAndBillingAddressTheSame}</FormLabel>
                    <FormCheck type="radio" label={labels.yesOption} value="Yes" checked={isServiceAndBillingAddressTheSame}
                                onChange={onChange}/>
                    <FormCheck type="radio" label="No" value="No" checked={!isServiceAndBillingAddressTheSame}
                                onChange={onChange}/>
                </Form.Group>
            </Col>
        </Row>
        {component}
    </>
}
