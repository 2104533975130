import {useSelector} from 'react-redux'
import {getNote} from '../../functions'
import {getSectionContent} from '../Notes'

export const Frontier = () => {
    const notes = useSelector(state => state.settings.notes)
    const data = useSelector(state => state.customer.data)

    const note = getNote(notes, 'frontier')

    if (!note) {
        return <></>
    }

    return <>
        {getSectionContent(note, data)}
    </>
}
