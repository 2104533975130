import {useSelector} from 'react-redux'
import {Col, Form, Row} from 'react-bootstrap'
import {FormCheck} from '../Utilities/Form'
import {isBusiness, useInputDataState} from '../../functions'

export const FlexOffer = () => {
    const flexOffer = useSelector(state => state.customer.data.flexOffer)
    const script = useSelector(state => state.customer.data.script)
    const [flexDecision, setFlexDecision] = useInputDataState('flexDecision')

    if (!flexOffer || isBusiness(script)) {
        return <></>
    }

    return <>
        <Row className="mb-3 mt-3">
            <Col>
                <div dangerouslySetInnerHTML={{__html: flexOffer}}></div>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormCheck label="Accepted" value="accepted" checked={flexDecision === 'accepted'}
                               onChange={setFlexDecision}/>
                    <FormCheck label="Rejected" value="rejected" checked={flexDecision === 'rejected'}
                               onChange={setFlexDecision}/>
                </Form.Group>
            </Col>
        </Row>
    </>
}
