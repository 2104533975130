import {configureStore} from '@reduxjs/toolkit'
import costumerSlice from './customerSlice'
import settingsSlice from './settingsSlice'
import validationSlice from './validationSlice'

export default configureStore({
    reducer: {
        customer: costumerSlice,
        settings: settingsSlice,
        validation: validationSlice,
    },
})
