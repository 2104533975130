import {Col, Form, Row} from 'react-bootstrap'
import {useInputDataState, useIsUSA, useLabels} from '../../functions'
import {FormCheck, FormLabel} from '../Utilities/Form'

export const MultiDwellingUnit = () => {
    const labels = useLabels()
    const [locationType, setLocationType] = useInputDataState('locationType')
    const [ownOrRent, setOwnOrRent] = useInputDataState('own')
    const [locationPermission, setLocationPermission] = useInputDataState('locationPermission')
    const isUSA = useIsUSA()

    if (!isUSA) {
        return
    }

    return <>
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel>{labels.locationType}</FormLabel>
                    <FormCheck label={labels.singleFamilyHome} type="radio" value="Single family home"
                               checked={locationType === 'Single family home'} onChange={setLocationType}/>
                    <FormCheck label={labels.multiLvelApartment} type="radio" value="Multi-level apartment"
                               checked={locationType === 'Multi-level apartment'} onChange={setLocationType}/>
                </Form.Group>
            </Col>
        </Row>
        {locationType === 'Multi-level apartment' ? <Row className="mb-3">
            <Col>
                <p style={{fontWeight: 'bold'}}><span
                    style={{color: 'rgb(156, 29, 27)'}}>{labels.mandatoryToRead}</span>
                    {labels.instalationDisclaimer}
                </p>
            </Col>
        </Row> : null}
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel>{labels.ownOrRent}</FormLabel>
                    <FormCheck type="radio" label={labels.own} value="own" onChange={setOwnOrRent}
                               checked={ownOrRent === 'own'}/>
                    <FormCheck type="radio" label={labels.rent} value="rent" onChange={setOwnOrRent}
                               checked={ownOrRent === 'rent'}/>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <FormLabel required={ownOrRent === 'rent'}>{labels.doYouHavePermission}</FormLabel>
                    <FormCheck type="radio" label="Yes" value="Yes" onChange={setLocationPermission}
                               checked={locationPermission === 'Yes'}/>
                    <FormCheck type="radio" label="No" value="No" onChange={setLocationPermission}
                               checked={locationPermission === 'No'}/>
                    {locationPermission === 'No' ? <p className="mt-2"
                                                      style={{fontWeight: 'bold'}}>{labels.landlordPermissionDisclaimer}</p> : null}
                </Form.Group>
            </Col>
        </Row>
    </>
}
