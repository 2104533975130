import {useSelector} from 'react-redux'
import {JsonView, darkStyles} from 'react-json-view-lite'
import "react-json-view-lite/dist/index.css";
import {Col, Row} from 'react-bootstrap'

export const DataViewer = () => {
    const data = useSelector(state => state.customer.data)

    const searchParams = new URLSearchParams(window.location.search)

    if (process.env.REACT_APP_ENV === 'production' || !searchParams.has('debug')) {
        return <></>
    }

    return <Row className="mt-5">
        <Col>
            <div style={{backgroundColor: '#eee'}}>
                <JsonView data={data} shouldInitiallyExpand={level => true} style={darkStyles}/>
            </div>
        </Col>
    </Row>
}
